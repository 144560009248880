import React from "react";
// import sections
import AllFeatures from "../components/sections/AllFeatures";

const Realisations = () => {
  return (
    <>
      <AllFeatures
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
    </>
  );
};

export default Realisations;
