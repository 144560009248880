import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Réalisations",
    paragraph: "Les dernières réalisations de Pixelio",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Site internet
                </div>
                <h3 className="mt-0 mb-12">Lineup Ocean</h3>
                <p className="m-0 mb-12">
                  LINEUP OCEAN est une startup basée à Montpellier (Hérault,
                  France) ayant pour mission d’accompagner et de préparer les
                  collectivités du littoral et leurs populations à la Résilience
                  Côtière.
                </p>
                <a
                  className="button button-primary mt-12"
                  href="https://lineup-ocean.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir le projet
                </a>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/projects/lineup.png")}
                  alt="Lineup Ocean"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Application mobile
                </div>
                <h3 className="mt-0 mb-12">Epitop</h3>
                <p className="m-0 mb-12">
                  Imaginée par des pharmaciens, Epitop c’est la nouvelle
                  application qui met en relation les pharmacies avec les
                  étudiants mais aussi tous les diplômés des métiers de la
                  pharmacie.
                </p>
                <a
                  className="button button-primary mt-12"
                  href="https://epitop.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir le projet
                </a>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/projects/epitop.png")}
                  alt="Epitop"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Application mobile
                </div>
                <h3 className="mt-0 mb-12">Linewise</h3>
                <p className="m-0 mb-12">
                  LineWise is the first mobile app that calculates line waiting
                  time based on crowdsourced data. This enables users to avoid
                  long lines and save their precious time for enjoying life.
                </p>
                <a
                  className="button button-primary mt-12"
                  href="https://whosinline.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir le projet
                </a>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/projects/linewise.png")}
                  alt="Linewise"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              className="mt-12"
              tag="a"
              color="primary"
              wideMobile
              href="/realisations"
            >
              Toutes les réalisations
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
