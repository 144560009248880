import classNames from "classnames";
import React from "react";
import Button from "../components/elements/Button";
import ButtonGroup from "../components/elements/ButtonGroup";
// import sections
import SectionHeader from "../components/sections/partials/SectionHeader";

const Contact = () => {
  const outerClasses = classNames("testimonial section");

  const innerClasses = classNames("section-inner");

  const sectionHeader = {
    title: "Contact",
    paragraph: "C’est ici que l’aventure commence !",
  };

  return (
    <>
      <section className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className="mt-12">
              <h3
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <span style={{ marginRight: "5px", color: "#9F80FD" }}>Vous avez </span>
                un besoin ? une idée ? un rêve ?
              </h3>
              <p>Pixelio est la pour vous accompagner !</p>
              <ButtonGroup>
                <Button
                  tag="a"
                  color="primary"
                  wideMobile
                  href="mailto:thomas@pixelio.io"
                >
                  Envoyer un mail
                </Button>
                <a
                  className="button button-dark"
                  wideMobile
                  href="https://calendly.com/tsflorus/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prendre un rdv
                </a>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
