import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const AllFeatures = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Réalisations",
    paragraph: "Développées avec ♥ par Pixelio",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <a
                href="https://lineup-ocean.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tiles-item-inner">
                  <Image
                    src={require("./../../assets/images/projects/lineup.png")}
                    alt="Lineup Ocean"
                    className="mb-12"
                    width={528}
                    height={396}
                  />
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Site internet
                  </div>
                  <h4 className="mt-0 mb-12">Lineup Ocean</h4>
                  <p className="text-sm m-0 mb-12">
                    LINEUP OCEAN est une startup basée à Montpellier (Hérault,
                    France) ayant pour mission d’accompagner et de préparer les
                    collectivités du littoral et leurs populations à la
                    Résilience Côtière.
                  </p>
                </div>
              </a>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <a
                href="https://epitop.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tiles-item-inner">
                  <Image
                    src={require("./../../assets/images/projects/epitop.png")}
                    alt="Epitop"
                    className="mb-12"
                    width={528}
                    height={396}
                  />
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Application mobile
                  </div>
                  <h4 className="mt-0 mb-12">Epitop</h4>
                  <p className="text-sm m-0 mb-12">
                    Imaginée par des pharmaciens, Epitop c’est la nouvelle
                    application qui met en relation les pharmacies avec les
                    étudiants mais aussi tous les diplômés des métiers de la
                    pharmacie.
                  </p>
                </div>
              </a>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <a
                href="https://whosinline.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tiles-item-inner">
                  <Image
                    src={require("./../../assets/images/projects/linewise.png")}
                    alt="Linewise"
                    className="mb-12"
                    width={528}
                    height={396}
                  />
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Application mobile
                  </div>
                  <h4 className="mt-0 mb-12">Linewise</h4>
                  <p className="text-sm m-0 mb-12">
                    LineWise is the first mobile app that calculates line
                    waiting time based on crowdsourced data. This enables users
                    to avoid long lines and save their precious time for
                    enjoying life.
                  </p>
                </div>
              </a>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <a
                href="https://allaroundathlete.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tiles-item-inner">
                  <Image
                    src={require("./../../assets/images/projects/a3.png")}
                    alt="All Around Athlete"
                    className="mb-12"
                    width={528}
                    height={396}
                  />
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Application mobile
                  </div>
                  <h4 className="mt-0 mb-12">All Around Athlete</h4>
                  <p className="text-sm m-0 mb-12">
                    All Around Athlète est une application vous permettant
                    d’accéder à des programmes de musculations personnalisés et
                    réalisés par des professionnels.
                  </p>
                </div>
              </a>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <a
                href="https://www.btotrip-consulting.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tiles-item-inner">
                  <Image
                    src={require("./../../assets/images/projects/b2Trip.png")}
                    alt="B2Trip"
                    className="mb-12"
                    width={528}
                    height={396}
                  />
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Site internet
                  </div>
                  <h4 className="mt-0 mb-12">B2Trip</h4>
                  <p className="text-sm m-0 mb-12">
                    B2Trip est la plateforme B2B dédiée aux professionnels du
                    tourisme. Elle vous permet de trouver de nouveaux clients et
                    partenaires adaptés.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AllFeatures.propTypes = propTypes;
AllFeatures.defaultProps = defaultProps;

export default AllFeatures;
